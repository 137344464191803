<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-row>
           <v-col cols="12" md="12">
                <v-toolbar>
                    <v-toolbar-title>Change Password</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-breadcrumbs :items="items"></v-breadcrumbs>
                </v-toolbar>

                <v-card class="mt-5">
                <v-form ref="mainForm">
                    <v-container class="py-0">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.curr_password" label="Current Password" outlined type="password" :rules="[rules.required]"></v-text-field>                   

                                <v-text-field v-model="form.new_password" label="New Password" :rules="[rules.required]" outlined type="password"></v-text-field>

                                <v-text-field v-model="form.confirm_password" label="Confirm Password" type="password" :rules="[rules.required,rules.cpass]" outlined></v-text-field>
                                
                                <v-btn color="cbtnSave" dark @click="save">
                                    <v-icon left dark>mdi-pencil-outline</v-icon>
                                    Change
                                </v-btn>

                                
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>

                </v-card>

            </v-col>
        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import ApAdminUserService from "@/services/ApAdminUserService"
export default {
  components:{DlgMessage},
  props: {
       caller: String
    },
  data() {
    return{
        //breadcrumb
        items: [
            {text:'Profile',disabled: false,href:'#',},
            {text:'Change Password',disabled: false,href:'#',},
            ],
        form:{
                login_id:"",
                current_password:"",
                new_password:"",
                confirm_password:"",
            },

            inputRules: [
             v => !!v || 'This field is required'
            ],

        rules: {
            required: value => !!value || 'Required.',
            counter: value => value.length == 6 || 'Need 6 digit',
            acounter: value => value.length == 6 || 'Need 6 digit',
            digit: value=> /[0-9][0-9][0-9][0-9][0-9][0-9]/.test(value) || 'Number only',
            email: value => /.+@.+\..+/.test(value) || "Please input a valid email.",
            cpass: value => value == this.form.new_password || 'Confirm password is not match!', 
            code: value => value == this.randomStr || 'Invalid 6 digit code!', 
        },
        errorFlag:false,            
    }
  },
  async created() {
  },
  
  methods:{
    dlgClose(){
        //   //console.log('dlgClose - dlgRegistration');
          if(!this.errorFlag){
            //   this.show = false;
            //console.log('push logout');
        localStorage.removeItem('token_auth');
        localStorage.removeItem('loginData');
        localStorage.removeItem('login_id');
        localStorage.removeItem('isLogin');
        this.$store.dispatch('aLogout');

            this.$router.push('/login')
          }
      },
    async save(){
        if(this.$refs.mainForm.validate()) {
            try{
                ApAdminUserService.change_own_password(this.form).then((response) => {
                    // //console.log(response)
                    this.$refs.dlgMsg.showDlgBox(true,'success','Change Password',response.data.error_message);
                    this.errorFlag = 0;

                }).catch((e)=>{
                    // //console.log(e.response);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Change Password',e.response.data.error_message);
                    this.errorFlag = 1;
                    
                })
                ;
            } catch(e) {
                //console.log(e);
                //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
            }

        }
    },
    

  }
}
</script>